/* eslint-disable react/no-array-index-key */
import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { SEO } from '../components/primitives';
import Main from '../components/_terms/Terms';

const pageTransition = {
  initial: {},
  enter: {},
  exit: {},
};

const Terms = ({ location: { pathname } }) => {
  //

  return (
    <motion.article
      className="relative"
      key={pathname}
      initial="initial"
      animate="enter"
      exit="exit"
      variants={pageTransition}
    >
      <SEO title="Terms of Use" description="Terms of Use" />
      <Main />
    </motion.article>
  );
};

export default Terms;

Terms.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};
